import { dateFromFirebaseDateTimeWithTimezone, dateFromFirebaseString, FirebaseItemTranslator } from './FirebaseItemResolver';
import {
    Match,
    MatchParameters,
    MatchSegment,
    MatchSegmentOption,
    MatchStatus
} from '../../interfaces/Match';
import { Competition } from '../../interfaces/Competition';
import { SportProvider } from '../../interfaces/SportProvider';
import {
    CompetitionData,
    FirebaseBackedMatch,
    FirebaseOfficialVerification,
} from '../FirebaseBackedMatch';

import { MatchEntry } from '../../interfaces/MatchEntry';
import { TeamMatchEntry } from '../sports/waterpolo/LegacySupport/TeamMatchEntry';
import firebase from 'firebase';
import { Venue } from '../../interfaces/Venue';

export class FirebaseMatchTranslator implements FirebaseItemTranslator<Match> {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;
    constructor(id: string, database: firebase.database.Database, sportProvider: SportProvider) {
        this.id = id;
        this.database = database;
        this.sportProvider = sportProvider;
    }

    async translate(snapshot, onSuccess: (Match) => void, onFailure) {
        // legacy mapping has team and opponent as the keys
        const {
            matchDate,
            hasStarted,
            hasFinished,
            defaultNumberOfTimeouts,
            matchSegmentCountOption,
            defaultMatchSegmentDuration,
        } = snapshot.val();
        var matchSegmentCountOptionObject = MatchSegmentOption.quarters;
        if (matchSegmentCountOption === 'halves') {
            matchSegmentCountOptionObject = MatchSegmentOption.halves;
        }

        const matchVal = snapshot.val();

        const competitionID = matchVal['competition'] || matchVal['league'];
        if (!competitionID) {
            onFailure('Missing competitionID');
            return;
        }
        const lightCapMatchEntry = matchVal['lightCapTeam'];
        const lightCapLegacyID = matchVal['team'];
        const lightCapTeamPromise = lightCapMatchEntry
            ? this.sportProvider
                .competitionEntryResolver(competitionID, lightCapMatchEntry)
                .asAPromise()
            : lightCapLegacyID
                ? this.sportProvider
                    .teamResolver(lightCapLegacyID)
                    .asAPromise()
                    .then((resolvedTeam) => {
                        return new TeamMatchEntry(resolvedTeam);
                    })
                : Promise.reject('Missing ids for lightCapTeam');

        const darkCapMatchEntry = matchVal['darkCapTeam'];
        const darkCapLegacyID = matchVal['opponent'];
        const darkCapTeamPromise = darkCapMatchEntry
            ? this.sportProvider
                .competitionEntryResolver(competitionID, darkCapMatchEntry)
                .asAPromise()
            : darkCapLegacyID
                ? this.sportProvider
                    .teamResolver(darkCapLegacyID)
                    .asAPromise()
                    .then((resolvedTeam) => {
                        return new TeamMatchEntry(resolvedTeam);
                    })
                : Promise.reject('Missing ids for darkCapTeam');
        var legacyDate: Date = dateFromFirebaseString(matchDate);

        const resolvedCompetition = await this.sportProvider.competitionResolver(competitionID).asAPromise();
    
            
        
        var venueDate: Date | undefined = undefined;
        const venueObject = matchVal['venue'] || {}
        var venueId = venueObject['id']
        var venue: Venue | undefined = undefined
        if (venueId) {
            // go to the event and find the venue 
            // from the venue, find the timezone
            const eventID = resolvedCompetition.event.id;
            venue = await this.sportProvider.venueResolver(eventID, venueId).asAPromise();
            const venueTimezone = venue.timezone;
            if (typeof venueTimezone == 'string') {
                const venueMatchDate = matchVal['venueDateTime']
                if (typeof venueMatchDate == 'string') {
                    venueDate = dateFromFirebaseDateTimeWithTimezone(venueMatchDate, venueTimezone)
                }
            }
        } else {
            // legacy support, the venue was embedded in the match object
            const venueName = venueObject['name']
            if (typeof venueName === 'string') {
                const subvenueObject = venueObject['subvenue'] || {}
                const subvenueName = subvenueObject['name']
                
                if (!venueId) {
                    venueId = venueName
                    if (subvenueName) {
                        venueId += `-${subvenueName}`
                    }
                }
                venue = {
                    id: venueId,
                    name: venueName,
                    subvenueName: subvenueName,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
            }
        }
        
        const resolvedMatchDate = venueDate || legacyDate
        
        if (resolvedMatchDate) {
            var status = MatchStatus.scheduled;
            if (hasFinished) {
                status = MatchStatus.completed;
            } else if (hasStarted) {
                status = MatchStatus.inProgress;
            }

            var dependentPromises: Promise<any>[] = [lightCapTeamPromise, darkCapTeamPromise];
            
            Promise.all(dependentPromises)
                .then((resolvedPromises) => {
                    const lightCapTeam: MatchEntry = resolvedPromises[0];
                    const darkCapTeam: MatchEntry = resolvedPromises[1];

                    var numberOfRegularMatchSegements = 4;
                    if (matchSegmentCountOptionObject === MatchSegmentOption.halves) {
                        numberOfRegularMatchSegements = 2;
                    }
                    var regularTimeMatchSegments: MatchSegment[] = [];
                    for (var i = 0; i < numberOfRegularMatchSegements; i++) {
                        const matchSegment = this.sportProvider.matchSegment(
                            matchSegmentCountOptionObject,
                            i
                        );
                        regularTimeMatchSegments.push(matchSegment);
                    }

                    var overtimeMatchSegments: MatchSegment[] = [];
                    const numberOfOvertimeSegments = 4;
                    for (var i = 0; i < numberOfOvertimeSegments; i++) {
                        const overtimeMatchSegment = this.sportProvider.matchSegment(
                            matchSegmentCountOptionObject,
                            numberOfRegularMatchSegements + i
                        );
                        overtimeMatchSegments.push(overtimeMatchSegment);
                    }
                    const overtimeMatchSegmentDuration = defaultMatchSegmentDuration;

                    const parameters: MatchParameters = {
                        regularTimeMatchSegmentOption: matchSegmentCountOptionObject,
                        regularTimeDescription: {
                            matchSegments: regularTimeMatchSegments,
                            durationInSeconds: defaultMatchSegmentDuration,
                        },
                        overtimeDescription: {
                            matchSegments: overtimeMatchSegments,
                            durationInSeconds: overtimeMatchSegmentDuration,
                        },
                        initialNumberOfTimeouts: defaultNumberOfTimeouts,
                    };
                    const verificationData = snapshot.val()['officialVerification'] || {};
                    const officialName = verificationData['name'];
                    const officialEmail = verificationData['email'];
                    const signatureDataPath = verificationData['signatureReferencePath'];
                    const invalidationDateString = verificationData['invalidationDate'];
                    const invalidationDate =
                        typeof invalidationDateString === 'string'
                            ? dateFromFirebaseString(invalidationDateString)
                            : undefined;
                    const officialData: FirebaseOfficialVerification | undefined =
                        typeof officialName === 'string' &&
                            typeof officialEmail === 'string' &&
                            typeof signatureDataPath === 'string'
                            ? {
                                officialName: officialName,
                                email: officialEmail,
                                invalidationDate: invalidationDate,
                                firebaseStorageRefPath: signatureDataPath,
                            }
                            : undefined;
                    const competitionData: CompetitionData = {
                        competition: resolvedCompetition,
                        officialVerification: officialData,
                    };

                    const capChanges = matchVal['capChanges'] || {};
                    const externalID = matchVal['externalID'];


                    const match: Match = new FirebaseBackedMatch(
                        this.id,
                        this.sportProvider,
                        this.database,
                        {
                            date: resolvedMatchDate,
                            venue
                        },
                        lightCapTeam,
                        darkCapTeam,
                        status,
                        parameters,
                        this.sportProvider.matchViewDescriptionProvider.fanViewDescription,
                        this.sportProvider.matchViewDescriptionProvider.officialViewDescription,
                        this.sportProvider.matchViewDescriptionProvider.scoreSheetViewDescription,

                        competitionData,
                        capChanges,
                        externalID
                    );
                    onSuccess(match);
                })
                .catch(() => {
                    onFailure('Failed to resolve match');
                });
        } else {
            onFailure('Failed to resolve match');
        }
    }
}
