import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Dropdown, ListGroup, ListGroupItem } from 'react-bootstrap';
import styled from 'styled-components';
import { Competition } from '../../../models/interfaces/Competition';
import { SportProvider } from '../../../models/interfaces/SportProvider';

interface Props {
    sportProvider: SportProvider;
    competitions: Competition[];
    selectedCompetition?: Competition;
    onSelectCompetition: (Competition) => void;
}

const CompetitionView = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
`;

const CompetitionText = styled.div`
    display: flex;
    flex-direction: column;
`;

export default function EventCompetitionPicker({
    sportProvider,
    competitions,
    selectedCompetition,
    onSelectCompetition,
}: Props) {
    return (
        <ListGroup>
            {competitions.map((competition) => {
                const isSelected = competition.id == selectedCompetition?.id;
                return (
                    <ListGroupItem
                        key={'eventcompetitionpicker' + competition.id}
                        onClick={() => {
                            onSelectCompetition(competition);
                        }}
                        style={
                            isSelected
                                ? {
                                      background: '#EFF3F6',
                                  }
                                : { background: 'white' }
                        }
                    >
                        <CompetitionView>
                            <CompetitionText>
                                <h6
                                    className="mb-0"
                                    style={
                                        isSelected
                                            ? { fontWeight: 'bold' }
                                            : { fontWeight: 'normal' }
                                    }
                                >
                                    {competition.attributes.name}
                                </h6>
                                <small
                                    className="mb-0"
                                    style={
                                        isSelected
                                            ? { fontWeight: 'bold' }
                                            : { fontWeight: 'normal' }
                                    }
                                >
                                    {` ${sportProvider.displayTextForTeamOrCompetitionAttributes(
                                        competition.attributes,
                                        true
                                    )} `}
                                </small>
                            </CompetitionText>
                            {isSelected && (
                                <div>{<FontAwesomeIcon icon={faChevronRight as IconProp} />}</div>
                            )}
                        </CompetitionView>
                    </ListGroupItem>
                );
            })}
        </ListGroup>
    );
}
