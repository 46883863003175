import { useState } from 'react';
import { Button, Modal, Spinner, Row, Col } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import { ListProvider } from '../../models/interfaces/ListProvider';
import { Resolver } from '../../models/interfaces/Resolver';
import SelectTeamDropDown from './modules/SelectTeamDropDown';
import useListProviderToArray from '../../helpers/useListProviderToArray';
import '../../components/styles/ReactDateTimePicker.css';
import {
    CompetitionEntry,
    isCompetitionEntry,
} from '../../models/interfaces/Competitions/CompetitionEntry';
import { EventVenue, isEventVenue, Venue } from '../../models/interfaces/Venue';
import { MatchMetadata } from '../../models/interfaces/Match';

interface Props {
    shouldShow: boolean;
    teamProvider: ListProvider<Resolver<CompetitionEntry>>;
    venueProvider: ListProvider<Resolver<EventVenue>>;
    title: string;
    submitBtnText: string;

    submitFunction: (
        metadata: MatchMetadata,
        lightColoredTeam: CompetitionEntry,
        darkColoredTeam: CompetitionEntry,
        onSuccess: () => void,
        onFailure: (string) => void
    ) => void;

    hideModal: () => void;
}

function sortTeams(a: CompetitionEntry, b: CompetitionEntry) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
}

export default function CreateMatchCrudModal({
    shouldShow,
    teamProvider,
    venueProvider,
    title,
    submitBtnText,
    submitFunction,
    hideModal,
}: Props) {
    const teams = useListProviderToArray({
        provider: teamProvider,
        isType: isCompetitionEntry,
        sortFn: sortTeams,
    });

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
    // new Intl.DateTimeFormat().resolvedOptions().timeZone - gets current users timezone or default to Pacific time
    const [selectedLightCapTeam, setSelectedLightCapTeam] = useState<CompetitionEntry | undefined>(
        undefined
    );
    const [selectedDarkCapTeam, setSelectedDarkCapTeam] = useState<CompetitionEntry | undefined>(
        undefined
    );

    const [selectedVenue, setSelectedVenue] = useState<Venue | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if (selectedDate && selectedLightCapTeam && selectedDarkCapTeam) {
            setLoading(true);
            submitFunction(
                {
                    date: selectedDate,
                    venue: selectedVenue
                },
                selectedLightCapTeam,
                selectedDarkCapTeam,
                () => {
                    setLoading(false);
                    hideModal();
                },
                (error) => {
                    setLoading(false);
                    setErrorMessage(error);
                }
            );
        }
    };

    return (
        <Modal show={shouldShow} onHide={hideModal} backdrop="static">
            <Modal.Header closeButton onHide={hideModal}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-4 justify-content-between">
                    <Col>
                        <Row>
                            <Col xs="auto">
                                <h5>Match Time</h5>
                            </Col>
                        </Row>
                        <Datetime
                            initialValue={selectedDate}
                            inputProps={{ placeholder: 'Enter match time' }}
                            onChange={(updatedDate) => {
                                if (typeof updatedDate === 'string') {
                                    setSelectedDate(undefined);
                                } else {
                                    setSelectedDate(updatedDate.toDate());
                                }
                            }}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <VenueDropdown
                                venueProvider={venueProvider}
                                onSelectVenue={setSelectedVenue}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="align-items-end justify-content-center">
                    <Col xs={12} md={5}>
                        <Row className="justify-content-md-center">
                            <Col xs="auto">
                                <h5>Light Cap Team</h5>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col xs="auto">
                                <SelectTeamDropDown
                                    dropdownId="light-cap-select"
                                    teams={teams}
                                    handleSelect={(team: CompetitionEntry) => {
                                        setSelectedLightCapTeam(team);
                                    }}
                                    title={
                                        selectedLightCapTeam ? selectedLightCapTeam.name : 'Select'
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={2} className="text-center">
                        <h4> VS </h4>
                    </Col>
                    <Col xs={12} md={5}>
                        <Row className="justify-content-md-center">
                            <Col xs="auto">
                                <h5>Dark Cap Team</h5>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col xs="auto">
                                <SelectTeamDropDown
                                    dropdownId="dark-cap-select"
                                    teams={teams}
                                    handleSelect={(team: CompetitionEntry) => {
                                        setSelectedDarkCapTeam(team);
                                    }}
                                    title={
                                        selectedDarkCapTeam ? selectedDarkCapTeam.name : 'Select'
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {errorMessage}
                <Button
                    disabled={
                        !selectedDate ||
                        !selectedLightCapTeam ||
                        !selectedDarkCapTeam ||
                        selectedLightCapTeam.id === selectedDarkCapTeam.id
                    }
                    onClick={handleSubmit}
                    variant="success"
                >
                    {loading ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        submitBtnText
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


interface VenueDropdownProps {
    selectedVenue?: Venue;
    venueProvider: ListProvider<Resolver<Venue>>
    onSelectVenue: (venue?: Venue) => void;
}

export function VenueDropdown({ selectedVenue, venueProvider, onSelectVenue }: VenueDropdownProps) {
    const venues = useListProviderToArray({
        provider: venueProvider,
        isType: isEventVenue,
    });

    const [selectedVenueID, setSelectedVenueID] = useState(selectedVenue?.id ?? '');

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const venueID = event.target.value;
        setSelectedVenueID(venueID);
        const selectedVenue = venues?.find(venue => venue.id === venueID);
        onSelectVenue(selectedVenue);
    };

    return (
        <Row>
            <Col>
                <Row>
                    <Col xs="auto">
                        <h5>Venue</h5>
                    </Col>
                </Row>
                <Row className='align-items-center'>
                    <Col xs="auto">
                        {typeof venues == 'undefined' ?
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            venues.length > 0 ? <select value={selectedVenueID} onChange={handleChange}>
                                <option value="">Select a Venue</option>
                                {venues.map(venue => (
                                    <option key={venue.id} value={venue.id}>
                                        {venue.name}{venue.subvenueName ? `, ${venue.subvenueName}` : ''}
                                    </option>
                                ))}
                            </select> : <>No venues for this event.</>}
                    </Col>
                </Row>

            </Col>
        </Row>
    );

}