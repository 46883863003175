import { Analytics, DisplayTextCategory, SportProvider } from '../../../interfaces/SportProvider';
import {
    TeamAttributes,
    TeamCategory,
    TeamDivision,
    TeamGender,
} from '../../../interfaces/TeamAttributes';
import { RosterEntry, Position, isRosterEntry } from '../../../interfaces/RosterEntry';
import _, { range } from 'lodash';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { getFirebaseConfig, getPublicURL } from '../../../../config/values';
import { FirebaseAuthProvider } from '../../FirebaseAuthProvider';
import { FirebaseCompetitionTranslator } from '../../translators/FirebaseCompetitionTranslator';
import { FirebaseItemResolver } from '../../translators/FirebaseItemResolver';
import { Competition, CompetitionAttributes } from '../../../interfaces/Competition';
import {
    Match,
    MatchEventRecordingGroup,
    MatchEventRecordingOption,
    MatchSegmentOption,
} from '../../../interfaces/Match';
import { StatType, StatTypeDescription, PerformerTypes } from '../../../interfaces/Stat';
import { FirebaseMatchTranslator } from '../../translators/FirebaseMatchTranslator';
import { FirebaseRosterEntryTranslator } from '../../translators/FirebaseRosterEntryTranslator';
import { FirebaseEventTranslator } from '../../translators/FirebaseEventTranslator';
import { FirebaseStatTranslator } from '../../translators/FirebaseStatTranslator';
import { FirebasePublicUserTranslator } from '../../translators/FirebasePublicUserTranslator';
import { PublicUser } from '../../../interfaces/User';
import { Event } from '../../../interfaces/Event';
import { Player } from '../../../interfaces/Player';
import { AccessGroup } from '../../../interfaces/AccessGroup';
import { Stat } from '../../../interfaces/Stat';
import { FirebaseAccessGroupTranslator } from '../../translators/FirebaseAccessGroupTranslator';
import {
    FirebaseResolverProvider,
    FirebaseUpdatingResolverProvider,
} from '../../providers/FirebaseUpdatingResolverProvider';
import axios from 'axios';

import { WaterPoloMatchDescriptionProvider } from './WaterPoloMatchDescriptionProvider';
import {
    WaterPoloStats,
    WaterPoloSubTypes,
    WaterPoloGoal,
    WaterPoloTimeout,
    WaterPoloMajorFoul,
    WaterPoloSave,
    WaterPoloExclusionSubtype,
    WaterPoloPenaltySubtype,
    WaterPoloFullTimeoutSubtype,
    WaterPolo30SecTimeoutSubtype,
    WaterPoloAssist,
    WaterPoloSteal,
    WaterPoloPlayerMisconduct,
    WaterPoloMinorMisconductSubtype,
    WaterPoloMajorMisconductSubtype,
    WaterPoloGameRemark,
    WaterPoloBrutalitySubtype,
    WaterPoloYellowCard,
    WaterPoloRedCard,
    WaterPoloBlock,
    WaterPoloShot,
    WaterPoloSprintWon,
    WaterPoloFlagrant,
    WaterPoloOffensiveFoul,
    WaterPoloEjectionDrawn,
    WaterPoloPenaltyDrawn,
    WaterPoloYellowRedCard,
} from './WaterPoloStats';
import { WaterPoloCompetitionLeaderDescriptions } from './WaterPoloCompetitionLeaderDescriptions';
import { Organization } from '../../../interfaces/Organization';
import { FirebaseOrganizationTranslator } from '../../translators/FirebaseOrganizationTranslator';
import { Team } from '../../../interfaces/Team';
import { FirebaseTeamTranslator } from '../../translators/FirebaseTeamTranslator';
import {
    CompetitionEntry,
    CompetitionEntryMetadata,
} from '../../../interfaces/Competitions/CompetitionEntry';
import { CompetitionEntryRequest } from '../../../interfaces/Competitions/CompetitionEntryRequest';
import { FirebaseBackedListProvider } from '../../FirebaseBackedListProvider';
import { FirebasePlayerTranslator } from '../../translators/FirebasePlayerTranslator';
import { FirebaseBackedCompetitionEntry } from '../../competitions/FirebaseBackedCompetitionEntry';
import { ResolverMapping } from './LegacySupport/ListProviderMapping';
import { TeamCompetitionEntry } from './LegacySupport/TeamCompetitionEntry';
import {
    AttemptColumnFormat,
    SimpleColumnFormat,
    MatchTableColumnDescription,
    PlayerTableColumnDescription,
} from '../../../interfaces/ViewDescriptions/TableColumnDescription';
import { MatchEntry } from '../../../interfaces/MatchEntry';
import { Resolver } from '../../../interfaces/Resolver';
import BuildUrl from 'build-url';
import { CompetitionEntryInvitation } from '../../../interfaces/Competitions/CompetitionAdmin';
import { EventVenue, Venue } from '../../../interfaces/Venue';

require('firebase/analytics');
require('firebase/performance');
require('firebase/app-check');

firebase.initializeApp(getFirebaseConfig());
// const appCheck = firebase.appCheck();
// appCheck.activate('6Lc4WMMbAAAAAIXkHmQtNdwBWEW5or1JKnELC4GK');

const database = firebase.database();
const storage = firebase.storage();

const WaterPoloTeamCategories: TeamCategory[] = [
    {
        name: 'High School',
        databaseValue: 'HIGH_SCHOOL',
        requiresDivisionForEventCreation: true,
        allowsRosterPropagation: true,
        allowsSharedEntryBetweenCompetitions: true,
        divisions: [
            {
                name: 'Varsity',
                databaseValue: 'VARSITY',
            },
            {
                name: 'Junior Varsity',
                databaseValue: 'JUNIOR_VARSITY',
            },
            {
                name: 'Frosh-Soph',
                databaseValue: 'FROSHSOPH',
            },
            {
                name: 'Freshmen',
                databaseValue: 'FRESHMAN',
            },
            {
                name: 'Novice',
                databaseValue: 'NOVICE',
            },
        ],
    },
    {
        name: 'WPA',
        databaseValue: 'AUSWP',
        requiresDivisionForEventCreation: false,
        allowsRosterPropagation: true,
        allowsSharedEntryBetweenCompetitions: false,
        divisions: [
            {
                name: 'National',
                databaseValue: 'NATIONAL',
            },
            {
                name: '19U',
                databaseValue: '19U',
            },
            {
                name: '18U',
                databaseValue: '18U',
            },
            {
                name: '17U',
                databaseValue: '17U',
            },
            {
                name: '16U',
                databaseValue: '16U',
            },
            {
                name: '15U',
                databaseValue: '15U',
            },
            {
                name: '14U',
                databaseValue: '14U',
            },
            {
                name: '13U',
                databaseValue: '13U',
            },
            {
                name: '12U',
                databaseValue: '12U',
            },
        ],
    },
    {
        name: 'USAWP',
        databaseValue: 'CLUB',
        requiresDivisionForEventCreation: false,
        allowsRosterPropagation: false,
        allowsSharedEntryBetweenCompetitions: false,
        divisions: [
            {
                name: 'Professional',
                databaseValue: 'PROFESSIONAL',
            },
            {
                name: 'Open',
                databaseValue: 'OPEN',
            },
            {
                name: 'Masters',
                databaseValue: 'MASTERS',
            },
            {
                name: '21U',
                databaseValue: '21U',
            },
            {
                name: '18U',
                databaseValue: '18U',
            },
            {
                name: '16U',
                databaseValue: '16U',
            },
            {
                name: '14U',
                databaseValue: '14U',
            },
            {
                name: '12U',
                databaseValue: '12U',
            },
            {
                name: '10U',
                databaseValue: '10U',
            },
            {
                name: '8U',
                databaseValue: '8U',
            },
            {
                name: 'Splash Ball',
                databaseValue: 'SPLASH_BALL',
            },
        ],
    },
    {
        name: 'AWP',
        databaseValue: 'AWP',
        requiresDivisionForEventCreation: false,
        allowsRosterPropagation: false,
        allowsSharedEntryBetweenCompetitions: false,
        divisions: [
            {
                name: '18U',
                databaseValue: '18U',
            },
            {
                name: '16U',
                databaseValue: '16U',
            },
            {
                name: '14U',
                databaseValue: '14U',
            },
        ],
    },
    {
        name: 'College',
        databaseValue: 'COLLEGE',
        requiresDivisionForEventCreation: true,
        allowsRosterPropagation: true,
        allowsSharedEntryBetweenCompetitions: true,
        divisions: [
            {
                name: 'D1',
                databaseValue: 'NCAA_D1',
            },
            {
                name: 'D2',
                databaseValue: 'NCAA_D2',
            },
            {
                name: 'D3',
                databaseValue: 'NCAA_D3',
            },
            {
                name: 'NAIA',
                databaseValue: 'NAIA',
            },
            {
                name: 'Club',
                databaseValue: 'CLUB',
            },
        ],
    },
    {
        name: 'National',
        databaseValue: 'NATIONAL',
        requiresDivisionForEventCreation: false,
        allowsRosterPropagation: false,
        allowsSharedEntryBetweenCompetitions: false,
        divisions: [
            {
                name: 'Senior',
                databaseValue: 'SENIOR',
            },
            {
                name: 'Junior',
                databaseValue: 'JUNIOR',
            },
        ],
    },
];

type GenderNames = "Men's" | "Women's" | 'Mixed';

/** From Men's to Boys or Women's to Girl's */
function updateGenderName(genderName: GenderNames) {
    if (genderName === MensGender.name) return "Boy's";
    if (genderName === WomensGender.name) return "Girl's";
    return genderName;
}
function isDivisionLowerGenderName(divisionDatabaseValue: string) {
    // match either an exact string match or get the first two digits. May need to update this if we include under 8's division
    const lowerGenderNameDivisionRegex = new RegExp(/^SPLASH_BALL$|^JUNIOR$|^\d{2}/, 'i');
    const matched = lowerGenderNameDivisionRegex.exec(divisionDatabaseValue);
    if (!matched) return false;
    const ageDivision = parseInt(matched[0]);
    if (ageDivision > 18) return false;
    // anything 18 or under or a matched string
    return true;
}

const MensGender: TeamGender = {
    name: "Men's",
    databaseValue: 'MALE',
};
const WomensGender: TeamGender = {
    name: "Women's",
    databaseValue: 'FEMALE',
};
const MixedGender: TeamGender = {
    name: 'Mixed',
    databaseValue: 'COED',
};
const WaterPoloGenders: TeamGender[] = [MensGender, WomensGender, MixedGender];

const WaterPoloPositions: Position[] = [
    {
        databaseValue: 'UTILITY',
        displayName: 'UT',
    },
    {
        databaseValue: 'GOALKEEPER',
        displayName: 'GK',
    },
    {
        databaseValue: 'TWO_METER',
        displayName: '2M',
    },
    {
        databaseValue: 'TWO_METER_DEFENSE',
        displayName: 'DEF',
    },
    {
        databaseValue: 'DRIVER',
        displayName: 'DR',
    },
    {
        databaseValue: 'LEFT_HANDER',
        displayName: 'LFT',
    },
];

class WaterPoloProvider implements SportProvider {
    name = 'Water Polo Scores';
    teamCategories = WaterPoloTeamCategories;
    teamGenders = WaterPoloGenders;
    statTypes = WaterPoloStats;
    playerPositions = WaterPoloPositions;
    playerCapNumbers = range(50)
        .concat([1000, 1001, 1002, 1003, 1004])
        .map((x) => {
            return this.capNumber(String(x));
        });

    defaultCapNumbers = this.playerCapNumbers.slice(0, 24);

    competitionSearchProvider = {
        search: (category: TeamCategory, divisions: TeamDivision[], genders: TeamGender[]) => {
            const refsToSearch = divisions.flatMap((division) => {
                return genders.map((gender) => {
                    return database.ref(
                        `competitionSearchIndex/${gender.databaseValue}/${category.databaseValue}/${division.databaseValue}`
                    );
                });
            });
            const promises = refsToSearch.flatMap((ref) => {
                return ref
                    .once('value')
                    .then((searchRefSnapshot) => {
                        var competitionIDs: string[] = [];
                        var val = searchRefSnapshot.val() ?? {};
                        for (const id in val) {
                            if (typeof id === 'string' && !!val[id]) {
                                competitionIDs.push(id);
                            }
                        }
                        return competitionIDs;
                    })
                    .then((competitionIDs) => {
                        const competitionResolvers: Resolver<Competition>[] = competitionIDs.map(
                            (competitionID) => {
                                return this.competitionResolver(competitionID);
                            }
                        );
                        return competitionResolvers;
                    })
                    .then((competitionResolvers) => {
                        return Promise.allSettled(
                            competitionResolvers.map((competitionResolver) => {
                                return competitionResolver.asAPromise();
                            })
                        ).then((competitionResults) => {
                            return competitionResults.flatMap((competitionResult) => {
                                if (competitionResult.status === 'fulfilled') {
                                    return [competitionResult.value];
                                } else {
                                    return [];
                                }
                            });
                        });
                    });
            });
            return Promise.allSettled(promises).then((competitionResults) => {
                const resolvedCompetitions = competitionResults.flatMap((competitionResult) => {
                    if (competitionResult.status === 'fulfilled') {
                        return [competitionResult.value];
                    } else {
                        return [];
                    }
                });
                return Promise.resolve(resolvedCompetitions.flat());
            });
        },
    };

    dataProvider = {
        uploadDataStringToPath: (dataString: string, path: string) => {
            const ref = storage.ref().child(path);
            return ref.putString(dataString).then(() => {
                return Promise.resolve();
            });
        },
        downloadDataStringFromPath: (path: string) => {
            return storage
                .ref(path)
                .getDownloadURL()
                .then((url) => {
                    return axios.get(url).then((response) => {
                        return response.data;
                    });
                });
        },
    };

    analytics: Analytics = {
        logPageView: (pageName: string) => {
            firebase.analytics.isSupported().then((isSupported) => {
                const firebaseAnalytics = firebase.analytics();
                firebaseAnalytics.setCurrentScreen(pageName);
                firebaseAnalytics.logEvent('page_view', { page_path: pageName });
            });
        },
    };

    authProvider = new FirebaseAuthProvider(this, firebase.auth(), database);
    resolverProvider = new FirebaseResolverProvider(this, database);
    updatingResolverProvider = new FirebaseUpdatingResolverProvider(this, database);
    matchViewDescriptionProvider = new WaterPoloMatchDescriptionProvider();

    timeoutStatTypes = [WaterPoloTimeout];
    personalFoulTypes = [WaterPoloMajorFoul];

    competitionLeaderDescriptions = WaterPoloCompetitionLeaderDescriptions;
    matchRecordingGroupsForAttributes(attributes) {
        if (attributes.category.databaseValue === 'AWP') {
            return [
                this.bookStatsRecordingGroup,
                this.detailRecordingGroup,
                this.awpFoulRecordingGroup,
            ];
        } else if (attributes.category.databaseValue === 'AUSWP') {
            return [
                this.wpaBookStatsRecordingGroup,
                this.detailRecordingGroup,
                this.foulRecordingGroup
            ]
        } else {
            return this.defaultMatchRecordingGroups;
        }
    }

    goalRecordingOption: MatchEventRecordingOption = {
        displayName: 'Goal',
        shortcutKey: 'g',
        typeDescription: new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
        requiresPlayer: true,
        requiresRemark: false,
    };

    // Kinda silly but some people are getting confused when recording goals during exclusions
    exclusionGoalRecordingOption: MatchEventRecordingOption = {
        displayName: 'Exc. Goal',
        shortcutKey: 'x',
        typeDescription: new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
        requiresPlayer: true,
        requiresRemark: false,
    };

    // Kinda silly but some people are getting confused when recording goals during penalties
    penaltyGoalRecordingOption: MatchEventRecordingOption = {
        displayName: 'Pen. Goal',
        shortcutKey: 'k',
        typeDescription: new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
        requiresPlayer: true,
        requiresRemark: false,
    };


    exclusionRecordingOption: MatchEventRecordingOption = {
        displayName: 'Exclusion',
        shortcutKey: 'e',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.player]),
            WaterPoloMajorFoul,
            WaterPoloExclusionSubtype
        ),
        requiresPlayer: true,
        requiresRemark: false,
    };

    penaltyRecordingOption: MatchEventRecordingOption = {
        displayName: 'Penalty',
        shortcutKey: 'p',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.player]),
            WaterPoloMajorFoul,
            WaterPoloPenaltySubtype
        ),
        requiresPlayer: true,
        requiresRemark: false,
    };

    timeoutRecordingOption: MatchEventRecordingOption = {
        displayName: 'Full Timeout',
        shortcutKey: 't',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.team]),
            WaterPoloTimeout,
            WaterPoloFullTimeoutSubtype
        ),
        requiresPlayer: false,
        requiresRemark: false,
    };

    timeout30SecondRecordingOption: MatchEventRecordingOption = {
        displayName: '30 sec. Timeout',
        shortcutKey: '#',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.team]),
            WaterPoloTimeout,
            WaterPolo30SecTimeoutSubtype
        ),
        requiresPlayer: false,
        requiresRemark: false,
    };

    gameRemarkRecordingOption: MatchEventRecordingOption = {
        displayName: 'Game Remark',
        shortcutKey: 'r',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.tableWorker]),
            WaterPoloGameRemark
        ),
        requiresPlayer: false,
        requiresRemark: true,
    };

    redCardRecordingOption: MatchEventRecordingOption = {
        displayName: 'Red Card',
        shortcutKey: 'c',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.player, PerformerTypes.team]),
            WaterPoloMajorFoul,
            WaterPoloRedCard
        ),
        requiresPlayer: false,
        requiresRemark: true,
    };

    yellowCardRecordingOption: MatchEventRecordingOption = {
        displayName: 'Yellow Card',
        shortcutKey: 'y',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.player, PerformerTypes.team]),
            WaterPoloMajorFoul,
            WaterPoloYellowCard
        ),
        requiresPlayer: false,
        requiresRemark: true,
    };

    yellowRedCardRecordingOption: MatchEventRecordingOption = {
        displayName: 'Yellow/Red Card',
        shortcutKey: 'd',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.team]),
            WaterPoloMajorFoul,
            WaterPoloYellowRedCard
        ),
        requiresPlayer: false,
        requiresRemark: true,
    };

    misconductRecordingOption: MatchEventRecordingOption = {
        displayName: 'Major Misconduct',
        shortcutKey: 'j',
        typeDescription: new StatTypeDescription(
            new Set([PerformerTypes.player]),
            WaterPoloPlayerMisconduct,
            WaterPoloMajorMisconductSubtype
        ),
        requiresPlayer: true,
        requiresRemark: true,
    };

    bookStatsRecordingGroup: MatchEventRecordingGroup = {
        displayName: 'Book',
        recordingOptions: [
            this.goalRecordingOption,
            this.exclusionRecordingOption,
            this.penaltyRecordingOption,
            this.timeoutRecordingOption,
            this.timeout30SecondRecordingOption,
            this.gameRemarkRecordingOption,
        ],
    };

    wpaBookStatsRecordingGroup: MatchEventRecordingGroup = {
        displayName: 'Book',
        recordingOptions: [
            this.goalRecordingOption,
            this.exclusionGoalRecordingOption,
            this.penaltyGoalRecordingOption,
            this.exclusionRecordingOption,
            this.penaltyRecordingOption,
            this.timeoutRecordingOption,
            this.timeout30SecondRecordingOption,
            this.gameRemarkRecordingOption,
        ],
    }

    awpFoulRecordingGroup: MatchEventRecordingGroup = {
        displayName: 'Fouls',
        recordingOptions: [
            {
                displayName: 'Minor Misconduct',
                shortcutKey: 'i',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloPlayerMisconduct,
                    WaterPoloMinorMisconductSubtype
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            this.misconductRecordingOption,
            {
                displayName: 'Flagrant Misconduct',
                shortcutKey: 'v',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloFlagrant
                ),
                requiresPlayer: true,
                requiresRemark: true,
            },
            this.yellowCardRecordingOption,
            this.redCardRecordingOption,
            this.yellowRedCardRecordingOption,
        ],
    };

    detailRecordingGroup = {
        displayName: 'Detail',
        recordingOptions: [
            {
                displayName: 'Save',
                shortcutKey: 's',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloSave
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Missed Shot',
                shortcutKey: 'm',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloShot
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Steal',
                shortcutKey: 'l',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloSteal
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Field Block',
                shortcutKey: 'f',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloBlock
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Assist',
                shortcutKey: 'a',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloAssist
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Drawn Exclusion',
                shortcutKey: 'u',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloEjectionDrawn
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Drawn Penalty',
                shortcutKey: 'n',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloPenaltyDrawn
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Offensive Foul',
                shortcutKey: 'o',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloOffensiveFoul
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Sprint Won',
                shortcutKey: 'w',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloSprintWon
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
        ],
    };

    foulRecordingGroup: MatchEventRecordingGroup = {
        displayName: 'Fouls',
        recordingOptions: [
            {
                displayName: 'Minor Misconduct',
                shortcutKey: 'i',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloPlayerMisconduct,
                    WaterPoloMinorMisconductSubtype
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Major Misconduct',
                shortcutKey: 'j',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloPlayerMisconduct,
                    WaterPoloMajorMisconductSubtype
                ),
                requiresPlayer: true,
                requiresRemark: false,
            },
            {
                displayName: 'Brutality',
                shortcutKey: 'b',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloMajorFoul,
                    WaterPoloBrutalitySubtype
                ),
                requiresPlayer: true,
                requiresRemark: true,
            },
            {
                displayName: 'Yellow Card',
                shortcutKey: 'y',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player, PerformerTypes.team]),
                    WaterPoloMajorFoul,
                    WaterPoloYellowCard
                ),
                requiresPlayer: false,
                requiresRemark: true,
            },
            this.yellowRedCardRecordingOption,
            {
                displayName: 'Red Card',
                shortcutKey: 'c',
                typeDescription: new StatTypeDescription(
                    new Set([PerformerTypes.player, PerformerTypes.team]),
                    WaterPoloMajorFoul,
                    WaterPoloRedCard
                ),
                requiresPlayer: false,
                requiresRemark: true,
            },
        ],
    }

    defaultMatchRecordingGroups: MatchEventRecordingGroup[] = [
        this.bookStatsRecordingGroup,
        this.detailRecordingGroup,
        this.foulRecordingGroup,
    ];

    csvMapping = {
        G: this.goalRecordingOption,
        E: this.exclusionRecordingOption,
        P: this.penaltyRecordingOption,
        TO: this.timeoutRecordingOption,
        'TO-30': this.timeout30SecondRecordingOption,
        N: this.gameRemarkRecordingOption,
        RC: this.redCardRecordingOption,
        YC: this.yellowCardRecordingOption,
        YRC: this.yellowRedCardRecordingOption,
        M: this.misconductRecordingOption,
    };

    matchRecordingOptionForCSVKey(csvKey: string) {
        return this.csvMapping[csvKey];
    }

    teamDetailMatchStatColumnDescriptions: MatchTableColumnDescription[] = [
        {
            id: 'GOALS',
            name: 'Goals',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                return match
                    .scoreForTeam(matchEntry)
                    .then((score) => {
                        var text = '--';
                        var count = 0;
                        if (typeof score === 'number') {
                            text = `${score}`;
                            count = score;
                        }
                        return {
                            text: text,
                            totalCount: count,
                        };
                    })
                    .catch((error) => {
                        return {
                            text: '--',
                            totalCount: 0,
                        };
                    });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'GOALS_ALLOWED',
            name: 'Goals Allowed',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                const opponent =
                    match.lightCapTeam.id === matchEntry.id
                        ? match.darkCapTeam
                        : match.lightCapTeam;
                return match
                    .scoreForTeam(opponent)
                    .then((score) => {
                        var text = '--';
                        var count = 0;
                        if (typeof score === 'number') {
                            text = `${score}`;
                            count = score;
                        }
                        return {
                            text: text,
                            totalCount: count,
                        };
                    })
                    .catch((error) => {
                        return {
                            text: '--',
                            totalCount: 0,
                        };
                    });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'SAVES',
            name: 'Saves',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSave),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                return Promise.resolve({
                    text: `${teamStats.length}`,
                    totalCount: teamStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'POWERPLAY_CONVERSION',
            name: 'Powerplay Conversion',
            statDescriptions: [
                new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloMajorFoul,
                    WaterPoloExclusionSubtype
                ),
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                const exclusionStats = opponentStats.filter((stat) => {
                    return stat.description.matchesDescription(
                        new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloMajorFoul,
                            WaterPoloExclusionSubtype
                        )
                    );
                });
                const numberOfConversions = teamStats.filter((goal) => {
                    if (goal.description.dataType.databaseValue !== WaterPoloGoal.databaseValue) {
                        return false;
                    }
                    return exclusionStats.find((exclusionStat) => {
                        return (
                            exclusionStat.matchSegment.databaseValue ==
                            goal.matchSegment.databaseValue &&
                            exclusionStat.timestamp - goal.timestamp < 20 &&
                            exclusionStat.timestamp - goal.timestamp >= 0
                        );
                    });
                }).length;

                return Promise.resolve({
                    text: `${numberOfConversions}/${exclusionStats.length}`,
                    numeratorCount: numberOfConversions,
                    denominatorCount: exclusionStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce(
                    (currentCount, format) => {
                        const numeratorCount = format['numeratorCount'] ?? 0;
                        const denomintorCount = format['denominatorCount'] ?? 0;
                        return {
                            numerator: currentCount.numerator + numeratorCount,
                            denominator: currentCount.denominator + denomintorCount,
                        };
                    },
                    { numerator: 0, denominator: 0 }
                );
                return `${totalCount.numerator}/${totalCount.denominator}`;
            },
        },
        {
            id: 'OPPONENT_POWERPLAY_CONVERSION',
            name: 'Opp. Powerplay Conversion',
            statDescriptions: [
                new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloMajorFoul,
                    WaterPoloExclusionSubtype
                ),
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                const exclusionStats = teamStats.filter((stat) => {
                    return stat.description.matchesDescription(
                        new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloMajorFoul,
                            WaterPoloExclusionSubtype
                        )
                    );
                });
                const numberOfConversions = opponentStats.filter((goal) => {
                    if (goal.description.dataType.databaseValue !== WaterPoloGoal.databaseValue) {
                        return false;
                    }
                    return exclusionStats.find((exclusionStat) => {
                        return (
                            exclusionStat.matchSegment.databaseValue ==
                            goal.matchSegment.databaseValue &&
                            exclusionStat.timestamp - goal.timestamp < 20 &&
                            exclusionStat.timestamp - goal.timestamp >= 0
                        );
                    });
                }).length;

                return Promise.resolve({
                    text: `${numberOfConversions}/${exclusionStats.length}`,
                    numeratorCount: numberOfConversions,
                    denominatorCount: exclusionStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce(
                    (currentCount, format) => {
                        const numeratorCount = format['numeratorCount'] ?? 0;
                        const denomintorCount = format['denominatorCount'] ?? 0;
                        return {
                            numerator: currentCount.numerator + numeratorCount,
                            denominator: currentCount.denominator + denomintorCount,
                        };
                    },
                    { numerator: 0, denominator: 0 }
                );
                return `${totalCount.numerator}/${totalCount.denominator}`;
            },
        },
        {
            id: 'PENALTIES_DRAWN',
            name: 'Penalties Drawn',
            statDescriptions: [
                new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloMajorFoul,
                    WaterPoloPenaltySubtype
                ),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                return Promise.resolve({
                    text: `${opponentStats.length}`,
                    totalCount: opponentStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'PENALTIES_AGAINST',
            name: 'Penalties Against',
            statDescriptions: [
                new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloMajorFoul,
                    WaterPoloPenaltySubtype
                ),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                return Promise.resolve({
                    text: `${teamStats.length}`,
                    totalCount: teamStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'MISSED_SHOTS',
            name: 'Missed Shots',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloShot),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                return Promise.resolve({
                    text: `${teamStats.length}`,
                    totalCount: teamStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'SHOTS_ALLOWED',
            name: 'Shots Allowed',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloShot),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                return Promise.resolve({
                    text: `${opponentStats.length}`,
                    totalCount: opponentStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'FIELD_BLOCKS',
            name: 'Field Blocks',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloBlock),
            ],
            textFormatter: (
                match: Match,
                matchEntry: MatchEntry,
                teamStats: Stat[],
                opponentStats: Stat[]
            ) => {
                return Promise.resolve({
                    text: `${teamStats.length}`,
                    totalCount: teamStats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
    ];

    teamDetailPlayerStatColumnDescriptions: PlayerTableColumnDescription[] = [
        {
            id: 'GOALS',
            name: 'Goals',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'SAVES',
            name: 'Saves',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSave),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'MISSED_SHOTS',
            name: 'Missed Shots',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloShot),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'ASSISTS',
            name: 'Assists',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloAssist),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'STEALS',
            name: 'Steals',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSteal),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'FIELD_BLOCKS',
            name: 'Field Blocks',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloBlock),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'EJECTIONS_AGAINST',
            name: 'Ejections Against',
            statDescriptions: [
                new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloMajorFoul,
                    WaterPoloExclusionSubtype
                ),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'PENALTIES_AGAINST',
            name: 'Penalties Against',
            statDescriptions: [
                new StatTypeDescription(
                    new Set([PerformerTypes.player]),
                    WaterPoloMajorFoul,
                    WaterPoloPenaltySubtype
                ),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'EJECTIONS_DRAWN',
            name: 'Ejections Drawn',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloEjectionDrawn),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
        {
            id: 'PENALTIES_DRAWN',
            name: 'Penalties Drawn',
            statDescriptions: [
                new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloPenaltyDrawn),
            ],
            textFormatter: (stats: Stat[]) => {
                return Promise.resolve({
                    text: `${stats.length}`,
                    totalCount: stats.length,
                });
            },
            totalFormatter: (formattedData: Array<SimpleColumnFormat | AttemptColumnFormat>) => {
                const totalCount = formattedData.reduce((currentCount, format) => {
                    const count = format['totalCount'] ?? 0;
                    return currentCount + count;
                }, 0);
                return `${totalCount}`;
            },
        },
    ];

    fanResolverProviders = {
        featuredEventProvider: new FirebaseBackedListProvider<Event>(
            database.ref(`featured/events`),
            (eventID) => {
                return this.eventResolver(eventID);
            }
        ),
        featuredCompetitionProvider: new FirebaseBackedListProvider<Competition>(
            database.ref(`featured/competitions`),
            (competitionID) => {
                return this.competitionResolver(competitionID);
            }
        ),
        featuredTeamProvider: new FirebaseBackedListProvider<Team>(
            database.ref(`featured/teams`),
            (teamID) => {
                return this.teamResolver(teamID);
            }
        ),
    };

    eventResolver(eventID: string) {
        const databaseRef = database.ref(`events/${eventID}`);
        return new FirebaseItemResolver<Event>(
            eventID,
            databaseRef,
            new FirebaseEventTranslator(eventID, this, database)
        );
    }

    organizationResolver(organizationID: string) {
        const databaseRef = database.ref(`organizations/${organizationID}`);
        return new FirebaseItemResolver<Organization>(
            organizationID,
            databaseRef,
            new FirebaseOrganizationTranslator(organizationID, this, database)
        );
    }

    competitionResolver(competitionID: string) {
        const databaseRef = database.ref(`competitions/${competitionID}`);
        return new FirebaseItemResolver<Competition>(
            competitionID,
            databaseRef,
            new FirebaseCompetitionTranslator(competitionID, this, database)
        );
    }

    competitionEntryRequestResolver(competitionID: string, entryRequestID: string) {
        const path = `competitions/${competitionID}/entryRequests/${entryRequestID}`;
        const databaseRef = database.ref(path);

        return new FirebaseItemResolver<CompetitionEntryRequest>(entryRequestID, databaseRef, {
            translate: (snapshot, onSuccess, onFailure) => {
                if (!snapshot.exists()) {
                    onFailure('Missing snapshot');
                    return;
                }
                const backingTeam = snapshot.val()['backingTeam'];
                const name = snapshot.val()['name'];
                const abbreviation = snapshot.val()['abbreviation'];
                const color = snapshot.val()['color'] || {};
                const red = color['red'];
                const blue = color['blue'];
                const green = color['green'];
                if (
                    !name ||
                    !abbreviation ||
                    typeof red !== 'number' ||
                    typeof blue !== 'number' ||
                    typeof green !== 'number'
                ) {
                    onFailure(
                        `Failed to resolve competition entry: ${competitionID}/entryRequests/${entryRequestID}`
                    );
                } else {
                    this.competitionResolver(competitionID)
                        .asAPromise()
                        .then((competition) => {
                            this.teamResolver(backingTeam)
                                .asAPromise()
                                .then((resolvedTeam) => {
                                    const competitionEntryRosterRef = database.ref(
                                        `competitions/${competitionID}/entryRequests/${entryRequestID}/roster`
                                    );
                                    const competitionEntryRequest: CompetitionEntryRequest = {
                                        id: entryRequestID,
                                        competition: competition,
                                        backingTeam: resolvedTeam,
                                        name: name,
                                        abbreviation: abbreviation,
                                        color: {
                                            red: red,
                                            blue: blue,
                                            green: green,
                                        },
                                        rosterProvider: new FirebaseBackedListProvider(
                                            competitionEntryRosterRef,
                                            (playerID) => {
                                                return this.playerResolver(playerID);
                                            }
                                        ),
                                    };
                                    onSuccess(competitionEntryRequest);
                                })
                                .catch((error) => {
                                    onFailure(
                                        `failed to resolve backing team for entry request with error: ${error}`
                                    );
                                });
                        });
                }
            },
        });
    }

    competitionInvitationResolver(id: string) {
        const competitionInvitationPath = `competitionInvitations/${id}`;
        return new FirebaseItemResolver<CompetitionEntryInvitation>(
            id,
            database.ref(competitionInvitationPath),
            {
                translate: (snapshot, onSuccess, onFailure) => {
                    const val = snapshot.val() || {};
                    const { email } = val;
                    const competitionID = val['competition'];
                    const competitionEntryID = val['competitionEntry'];
                    this.competitionEntryResolver(competitionID, competitionEntryID)
                        .asAPromise()
                        .then((competitionEntry) => {
                            const app_base_url = getPublicURL();
                            const linkURL = BuildUrl(app_base_url, {
                                path: 'redeemCompetitionInvitation',
                                queryParams: {
                                    competitionInvitationID: id,
                                },
                            });
                            const entryInvitation: CompetitionEntryInvitation = {
                                id,
                                email,
                                competitionEntry,
                                linkURL,
                            };
                            onSuccess(entryInvitation);
                        })
                        .catch((error) => {
                            onFailure(error);
                        });
                },
            }
        );
    }

    competitionEntryResolver(competitionID: string, entryID: string) {
        const competitionResolver = this.competitionResolver(competitionID);
        return new ResolverMapping<Competition, CompetitionEntry>(
            entryID,
            competitionResolver,
            (competition) => {
                if (!!competition['isLegacyEvent']) {
                    // St. Mark's in State Championship
                    return this.teamResolver(entryID)
                        .asAPromise()
                        .then((team) => {
                            if (entryID === 'c344bd40-04e5-46ff-ad37-b86c33e7b0f2') {
                                return this.teamResolver('7ab2780e-8a10-4b9d-b284-51319155f5e9')
                                    .asAPromise()
                                    .then((stMarks) => {
                                        return new TeamCompetitionEntry(team, competition, stMarks);
                                    });
                            }
                            return new TeamCompetitionEntry(team, competition);
                        });
                } else {
                    const path = `competitions/${competitionID}/entries/${entryID}`;
                    const databaseRef = database.ref(path);
                    return new FirebaseItemResolver<CompetitionEntry>(entryID, databaseRef, {
                        translate: (snapshot, onSuccess, onFailure) => {
                            const val = snapshot.val();
                            if (!val) {
                                console.log('rejecting due to missing val');
                                onFailure('Failed to get val from competitionEntry snapshot');
                                return;
                            }
                            const name = val['name'];
                            const abbreviation = val['abbreviation'];
                            const backingTeam = val['backingTeam'];

                            const resolveCompetitionEntry = (resolvedCompetition, resolvedTeam) => {
                                if (!name) {
                                    console.log('rejecting due to missing name');
                                    onFailure('Failed to resolve a name for competition entry');
                                    return;
                                }

                                if (!abbreviation) {
                                    console.log('rejecting due to missing abbreviation');
                                    onFailure(
                                        'Failed to resolve an abbreviation for competition entry'
                                    );
                                    return;
                                }

                                const color = val['color'] || {};
                                const red = color['red'];
                                const blue = color['blue'];
                                const green = color['green'];
                                if (
                                    typeof red !== 'number' ||
                                    typeof blue !== 'number' ||
                                    typeof green !== 'number'
                                ) {
                                    onFailure('Failed to resolve a color for competition entry');
                                    return;
                                }

                                const competitionEntry: CompetitionEntry =
                                    new FirebaseBackedCompetitionEntry(
                                        entryID,
                                        this,
                                        database,
                                        resolvedCompetition,
                                        name,
                                        abbreviation,
                                        color,
                                        {
                                            backingTeam: resolvedTeam,
                                            externalID: val['externalID']
                                        }
                                    );
                                onSuccess(competitionEntry);
                            };

                            if (backingTeam) {
                                this.teamResolver(backingTeam)
                                    .asAPromise()
                                    .then((resolvedTeam) => {
                                        resolveCompetitionEntry(competition, resolvedTeam);
                                    })
                                    .catch(() => {
                                        onFailure(
                                            'Failed to resolve a backing team for competition entry'
                                        );
                                    });
                            } else {
                                resolveCompetitionEntry(competition, undefined);
                            }
                        },
                    }).asAPromise();
                }
            }
        );
    }

    matchResolver(matchID: string) {
        const ref = database.ref(`matches/${matchID}`);
        return new FirebaseItemResolver<Match>(
            matchID,
            ref,
            new FirebaseMatchTranslator(matchID, database, this)
        );
    }

    accessGroupResolver(accessGroupID: string) {
        const ref = database.ref(`accessGroups/${accessGroupID}`);
        return new FirebaseItemResolver<AccessGroup>(
            accessGroupID,
            ref,
            new FirebaseAccessGroupTranslator(accessGroupID, this, database)
        );
    }

    teamResolver(teamID: string) {
        const ref = database.ref(`teams/${teamID}`);
        return new FirebaseItemResolver<Team>(
            teamID,
            ref,
            new FirebaseTeamTranslator(teamID, this, database)
        );
    }

    venueResolver(eventID: string, id: string): Resolver<EventVenue> {
        return new FirebaseItemResolver(
            id,
            database.ref(`events/${eventID}/venues/${id}/metadata`),
            {
                translate: (snapshot, onSuccess, onFailure) => {
                    const val = snapshot.val() || {}
                    const name = typeof val['name'] === 'string' ? val['name'] : undefined
                    const subvenue = val['subvenue'] || {}
                    const subvenueName = typeof subvenue['name'] === 'string' ? subvenue['name'] : undefined

                    const timezone = val['timezone']
                    if (name) {
                        onSuccess({
                            id,
                            name,
                            subvenueName,
                            timezone,
                            matchProvider: new FirebaseBackedListProvider(
                                database.ref(`events/${eventID}/venues/${id}/matches`),
                                (matchID) => {
                                    return this.matchResolver(matchID)
                                }
                            )
                        })
                    } else {
                        onFailure('Missing venue name')
                    }
                }
            })
    }

    rosterEntryResolver(competitionID: string, competitionEntryID: string, playerID: string) {
        const ref = database.ref(
            `competitions/${competitionID}/entries/${competitionEntryID}/roster/${playerID}`
        );
        if (!competitionID || !competitionEntryID) {
            console.log('missing competition ids for regular resolver');
        }
        return new FirebaseItemResolver<RosterEntry>(
            playerID,
            ref,
            new FirebaseRosterEntryTranslator(
                playerID,
                database,
                this,
                competitionID,
                competitionEntryID
            )
        );
    }

    playerResolver(playerID: string) {
        const ref = database.ref(`players/${playerID}`);
        return new FirebaseItemResolver<Player>(
            playerID,
            ref,
            new FirebasePlayerTranslator(playerID, database, this)
        );
    }

    statResolver(statID: string) {
        return new FirebaseItemResolver<Stat>(
            statID,
            database.ref(`stats/${statID}`),
            new FirebaseStatTranslator(statID, database, this)
        );
    }

    teamValues(categoryDatabaseValue, divisionDatabaseValue) {
        const foundTeamCategory = this.teamCategories.find((teamCategory) => {
            return teamCategory.databaseValue === categoryDatabaseValue;
        });

        if (foundTeamCategory) {
            const foundDivision = foundTeamCategory.divisions.find((division) => {
                return division.databaseValue === divisionDatabaseValue;
            });
            return {
                category: foundTeamCategory,
                division: foundDivision,
            };
        }
        return {
            category: undefined,
            division: undefined,
        };
    }

    teamGender(genderDatabaseValue) {
        const foundGender = this.teamGenders.find((gender) => {
            if (gender.databaseValue === genderDatabaseValue) {
                return true;
            } else if (genderDatabaseValue === 'MALE_YOUTH') {
                return MensGender;
            } else if (genderDatabaseValue === 'FEMALE_YOUTH') {
                return WomensGender;
            } else {
                return false;
            }
        });
        return {
            gender: foundGender,
        };
    }

    statType(databaseValue) {
        return this.statTypes.find((statType) => {
            return statType.databaseValue === databaseValue;
        });
    }

    statSubType(databaseValue) {
        return WaterPoloSubTypes.find((statType) => {
            return statType.databaseValue === databaseValue;
        });
    }

    statDescription(typeDatabaseValue, subtypeDatabaseValue) {
        // CBTODO: make this more graceful
        const statType = this.statType(typeDatabaseValue);
        const subtype = this.statSubType(subtypeDatabaseValue);
        if (!statType) {
            return undefined;
        } else {
            var supportedPerfomers = new Set([PerformerTypes.player]);
            if (
                statType.databaseValue === WaterPoloTimeout.databaseValue ||
                subtype?.databaseValue === WaterPoloYellowRedCard.databaseValue
            ) {
                supportedPerfomers = new Set([PerformerTypes.team]);
            } else if (statType.databaseValue === WaterPoloGameRemark.databaseValue) {
                supportedPerfomers = new Set([PerformerTypes.tableWorker]);
            } else if (
                subtype?.databaseValue === WaterPoloYellowCard.databaseValue ||
                subtype?.databaseValue === WaterPoloRedCard.databaseValue
            ) {
                supportedPerfomers = new Set([PerformerTypes.player, PerformerTypes.team]);
            }

            return new StatTypeDescription(supportedPerfomers, statType, subtype);
        }
    }

    playerPosition(databaseValue) {
        return this.playerPositions.find((position) => {
            return position.databaseValue === databaseValue;
        });
    }

    matchStatDescriptionsToDisplay() {
        return [
            WaterPoloGoal,
            WaterPoloAssist,
            WaterPoloSave,
            WaterPoloSteal,
            WaterPoloBlock,
            WaterPoloMajorFoul,
            WaterPoloEjectionDrawn,
            WaterPoloPenaltyDrawn,
            WaterPoloOffensiveFoul,
        ].map((statType) => {
            return new StatTypeDescription(new Set([PerformerTypes.player]), statType);
        });
    }

    capNumber(databaseValue) {
        var displayName = String(databaseValue);
        if (databaseValue === '0') {
            displayName = '1';
        } else if (databaseValue === '1') {
            displayName = '1A';
        } else if (databaseValue === '1000') {
            displayName = '1B';
        } else if (databaseValue === '1001') {
            displayName = '1C';
        } else if (databaseValue === '1002') {
            displayName = '1D';
        } else if (databaseValue === '1003') {
            displayName = '1E';
        } else if (databaseValue === '1004') {
            displayName = '1F';
        }
        return {
            databaseValue: databaseValue,
            displayName: displayName,
            sortValue: Number(databaseValue),
        };
    }

    matchSegment(matchSegmentOption: MatchSegmentOption, databaseValue: number) {
        var displayName = '';
        if (matchSegmentOption === MatchSegmentOption.quarters) {
            if (databaseValue === 0) {
                displayName = 'Q1';
            } else if (databaseValue === 1) {
                displayName = 'Q2';
            } else if (databaseValue === 2) {
                displayName = 'Q3';
            } else if (databaseValue === 3) {
                displayName = 'Q4';
            } else if (databaseValue > 3) {
                const overtimePeriod = databaseValue - 3;
                displayName = 'OT' + String(overtimePeriod);
            }
        } else if (matchSegmentOption === MatchSegmentOption.halves) {
            if (databaseValue === 0) {
                displayName = 'H1';
            } else if (databaseValue === 1) {
                displayName = 'H2';
            } else if (databaseValue > 1) {
                const overtimePeriod = databaseValue - 1;
                displayName = 'OT' + String(overtimePeriod);
            }
        }
        return {
            databaseValue: databaseValue,
            displayName: displayName,
        };
    }

    shouldDisplayStatTypeInScoreSheet(statType: StatType) {
        return (
            statType.databaseValue === WaterPoloGoal.databaseValue ||
            statType.databaseValue === WaterPoloMajorFoul.databaseValue ||
            statType.databaseValue === WaterPoloTimeout.databaseValue ||
            statType.databaseValue === WaterPoloGameRemark.databaseValue ||
            statType.databaseValue === WaterPoloFlagrant.databaseValue ||
            statType.databaseValue === WaterPoloPlayerMisconduct.databaseValue
        );
    }

    displayTextForAddedStat(stat: Stat, match: Match, existingStats: Stat[]) {
        if (
            stat.description.dataType.databaseValue === WaterPoloMajorFoul.databaseValue &&
            isRosterEntry(stat.performer)
        ) {
            const rosterEntry = stat.performer;
            const numberOfFouls = existingStats.reduce((numberOfFoulsCount, existingStat) => {
                const value =
                    existingStat.description.dataType.databaseValue ===
                        WaterPoloMajorFoul.databaseValue &&
                        isRosterEntry(existingStat.performer) &&
                        existingStat.performer.id === rosterEntry.id
                        ? 1
                        : 0;
                return numberOfFoulsCount + value;
            }, 0);
            const foulCategory: DisplayTextCategory = numberOfFouls >= 3 ? 'warning' : 'success';
            const foulText = {
                text: `#${rosterEntry.capNumber.displayName} has ${numberOfFouls + 1} foul(s)`,
                category: foulCategory,
            };

            return Promise.resolve(foulText);
        } else if (stat.description.dataType.databaseValue === WaterPoloSprintWon.databaseValue) {
            const duplicateSprint = existingStats.find((existingSprint) => {
                return (
                    existingSprint.description.dataType.databaseValue ===
                    WaterPoloSprintWon.databaseValue &&
                    existingSprint.matchSegment.databaseValue === stat.matchSegment.databaseValue
                );
            });
            if (duplicateSprint) {
                const sprintCategory: DisplayTextCategory = 'warning';
                return Promise.resolve({
                    text: `WARNING: Found another Sprint Won in ${stat.matchSegment.displayName}. Did you remember to update the quarter?`,
                    category: sprintCategory,
                });
            }
        } else {
            var muchLaterStatInMatchSegment: Stat | undefined = undefined;
            var hasEncounteredStatFromPreviousMatchSegment = stat.matchSegment.databaseValue == 0;
            for (var i = 0; i < existingStats.length; i++) {
                const existingStat = existingStats[i];
                if (existingStat.matchSegment.databaseValue === stat.matchSegment.databaseValue) {
                    const existingStatOccurredLaterInTimeline =
                        existingStat.timestamp - (stat.timestamp - 20) < 0;
                    if (existingStatOccurredLaterInTimeline) {
                        muchLaterStatInMatchSegment = existingStat;
                        break;
                    }
                }
                if (
                    existingStat.matchSegment.databaseValue ===
                    stat.matchSegment.databaseValue - 1
                ) {
                    hasEncounteredStatFromPreviousMatchSegment = true;
                }
            }
            const timelineWarning: DisplayTextCategory = 'warning';
            if (muchLaterStatInMatchSegment) {
                return Promise.resolve({
                    text: `WARNING: The provided time occurs before existing stats, did you remember to update the quarter?`,
                    category: timelineWarning,
                });
            }
            if (!hasEncounteredStatFromPreviousMatchSegment) {
                return Promise.resolve({
                    text: `WARNING: Recording a stat for ${stat.matchSegment.displayName} but we do not have stats from the previous quarter.`,
                    category: timelineWarning,
                });
            }
        }

        const category: DisplayTextCategory = 'success';
        return Promise.resolve({
            text: 'Save Successful',
            category: category,
        });
    }

    displayTextForTeamOrCompetitionAttributes(
        attributes: TeamAttributes | CompetitionAttributes,
        hideCategory?: boolean
    ) {
        let {
            gender: { name: genderName },
            category: { name: categoryName },
            division: { name: divisionName, databaseValue: divisionDatabaseValue },
        } = attributes;

        if (categoryName === 'High School') {
            genderName = updateGenderName(genderName as GenderNames);
            if (hideCategory) {
                return `${genderName} ${divisionName}`;
            }
            return `${genderName} ${categoryName} ${divisionName}`;
        } else if (isDivisionLowerGenderName(divisionDatabaseValue)) {
            genderName = updateGenderName(genderName as GenderNames);
        }

        if (categoryName === 'USAWP' || categoryName === 'AUSWP') {
            if (hideCategory) {
                return `${genderName} ${divisionName}`;
            } else {
                return `${categoryName} ${genderName} ${divisionName}`;
            }
        }

        if (categoryName === 'College' && !!!hideCategory) {
            // this is case sensitive
            if (divisionDatabaseValue.includes('NCAA')) {
                return `${genderName} NCAA ${divisionName}`;
            }
            return `${genderName} College ${divisionName}`;
        }
        if (hideCategory) {
            return `${genderName} ${divisionName}`;
        }
        return `${genderName} ${divisionName} ${categoryName}`;
    }
}

export const WaterPolo: SportProvider = new WaterPoloProvider();
