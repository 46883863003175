import axios, { AxiosError } from 'axios';

import {
    Event,
    EventAdmin,
    EventAdminError,
    EventAdminPermission,
    EventAttributes,
    EventCompetitionEditor,
    EventExternalMatchSource,
    EventEditor,
    EventRegistrationError,
    ManageCompetitionError,
    UpdatableEventAttributes,
    EventArticle,
    EventNotification,
    VenueName,
    EventInformationItem,
    EventInformationContent,
} from '../interfaces/Event';
import {
    CompetitionPayment,
    CompetitionRegistrationRequest,
    EventRegistration,
    EventRegistrationAttributes,
    RegistrationRosterEntry,
} from '../interfaces/EventRegistration';
import { Team } from '../interfaces/Team';
import { Competition, CompetitionAttributes } from '../interfaces/Competition';
import { ListProvider } from '../interfaces/ListProvider';
import { Resolver } from '../interfaces/Resolver';
import { SportProvider } from '../interfaces/SportProvider';
import { User, PublicUser } from '../interfaces/User';
import { FirebaseBackedListProvider } from './FirebaseBackedListProvider';
import {
    CompetitionEntry,
    CompetitionEntryMetadata,
} from '../interfaces/Competitions/CompetitionEntry';
import firebase from 'firebase';
import moment from 'moment';
import { FirebaseBackedEventEditor } from './event_admin/FirebaseBackedEventEditor';
import { FirebaseBackedEventCompetitionEditor } from './event_admin/FirebaseBackedEventCompetitionEditor';
import { RevSportIntegrationSupport } from './RevSportIntegrationSupport';
import { RevSportIntegration } from '../interfaces/RevSportIntegration';
import { EventVenue } from '../interfaces/Venue';
import { dateFromFirebaseString, FirebaseItemResolver } from './translators/FirebaseItemResolver';
import { url } from 'inspector';

export class FirebaseBackedEvent implements Event {
    id: string;
    sportProvider: SportProvider;
    database: firebase.database.Database;
    attributes: EventAttributes;
    registration?: EventRegistration;
    enteredTeams: ListProvider<Resolver<Team>>;
    competitionProvider: ListProvider<Resolver<Competition>>;
    venues: ListProvider<Resolver<EventVenue>>;
    articles: ListProvider<Resolver<EventArticle>>;
    notifications: ListProvider<Resolver<EventNotification>>;
    informationItems: ListProvider<Resolver<EventInformationItem>>;
    externalMatchSource: EventExternalMatchSource;

    constructor(
        id: string,
        sportProvider: SportProvider,
        database: firebase.database.Database,
        attributes: EventAttributes,
        externalMatchSource: EventExternalMatchSource,
        registrationAttributes?: EventRegistrationAttributes
    ) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;
        this.attributes = attributes;
        this.externalMatchSource = externalMatchSource;
        if (registrationAttributes) {
            this.registration = new EventRegistrationImplementation(this, registrationAttributes);
        } else {
            this.registration = undefined;
        }

        this.competitionProvider = new FirebaseBackedListProvider<Competition>(
            database.ref(`events/${id}/competitions`),
            (competitionID) => {
                return sportProvider.competitionResolver(competitionID);
            }
        );

        this.enteredTeams = new FirebaseBackedListProvider<Team>(
            database.ref(`events/${id}/enteredTeams`),
            (teamID) => {
                return sportProvider.teamResolver(teamID);
            }
        );

        this.venues = new FirebaseBackedListProvider<EventVenue>(
            database.ref(`events/${id}/venues`),
            (venueId) => {
                return this.sportProvider.venueResolver(this.id, venueId)
            }
        )

        this.articles = new FirebaseBackedListProvider<EventArticle>(
            database.ref(`events/${id}/articles`),
            (articleId) => {
                return new FirebaseItemResolver(
                    articleId,
                    this.database.ref(`events/${id}/articles/${articleId}`),
                    {
                        translate: (snapshot, onSuccess, onFailure) => {
                            const val = snapshot.val() || {}
                            const publishDateString = val['publishDate']
                            const publishDate = dateFromFirebaseString(publishDateString)
                            if (publishDate) {
                                onSuccess({
                                    id: articleId,
                                    publishDate,
                                    thumbnailUrl: val['thumbnailUrl'],
                                    title: val['title'],
                                    url: val['url']
                                })
                            } else {
                                onFailure('Invalid publish date')
                            }
                        }
                    }
                )
            }
        );

        this.notifications = new FirebaseBackedListProvider<EventNotification>(
            database.ref(`events/${id}/notifications`),
            (notificationId) => {
                return new FirebaseItemResolver(
                    notificationId,
                    this.database.ref(`events/${id}/notifications/${notificationId}`),
                    {
                        translate: (snapshot, onSuccess, onFailure) => {
                            const val = snapshot.val() || {}
                            const message = val['message']
                            const dateString = val['publishDate']
                            const publishDate = dateFromFirebaseString(dateString)
                            if (typeof message === 'string' && publishDate) {
                                onSuccess({ id: notificationId, message, publishDate })
                            } else {
                                onFailure('Invalid message')
                            }
                        }
                    }
                )
            }
        );

        this.informationItems = new FirebaseBackedListProvider<EventInformationItem>(
            database.ref(`events/${id}/info`),
            (informationItemID) => {
                return new FirebaseItemResolver(
                    informationItemID,
                    this.database.ref(`events/${id}/info/${informationItemID}`),
                    {
                        translate: (snapshot, onSuccess, onFailure) => {
                            const val = snapshot.val() || {}
                            const text = val['text']
                            const createdDateString = val['createdDate']
                            const createdDate = dateFromFirebaseString(createdDateString)
                            const content = val['content']

                            if (typeof text === 'string' && typeof content === 'string') {
                                onSuccess({ id: informationItemID, createdDate, text, content: {
                                    url: content
                                } })
                            } else {
                                onFailure('Invalid information item')
                            }
                        }
                    }
                )
            }
        );
    }

    redeemAdminInvitation(token: string, user: User) {
        return user
            .fetchVerificationToken()
            .then((userToken) => {
                const body = {
                    userToken,
                    invitationID: token,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/events/adminInvitations/redeem';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`event.redeemAdmin failed with: ${response.statusText}`);
                }
            });
    }

    adminForUser(user: User) {
        return this.sportProvider.resolverProvider
            .eventAdminPermissionResolver(this.id, user.id)
            .asAPromise()
            .then(({ user: _, permission }) => {
                return new FirebaseBackedEventAdmin(
                    this,
                    user,
                    permission,
                    this.sportProvider,
                    this.externalMatchSource
                );
            });
    }
}

class FirebaseBackedEventAdmin implements EventAdmin {
    event: FirebaseBackedEvent;
    user: User;
    externalMatchSource: EventExternalMatchSource;
    eventEditor?: EventEditor;
    competitionEditor?: EventCompetitionEditor;
    revSportIntegration?: RevSportIntegration;
    sportProvider: SportProvider;

    constructor(
        event: FirebaseBackedEvent,
        user: User,
        permission: EventAdminPermission,
        sportProvider: SportProvider,
        externalMatchSource: EventExternalMatchSource
    ) {
        this.event = event;
        this.user = user;
        this.sportProvider = sportProvider;
        this.externalMatchSource = externalMatchSource;
        if (permission == EventAdminPermission.canEditEvent) {
            this.eventEditor = new FirebaseBackedEventEditor(user, event);
        }

        if (
            permission == EventAdminPermission.canEditEvent ||
            permission == EventAdminPermission.canEditCompetitions
        )
            this.competitionEditor = new FirebaseBackedEventCompetitionEditor(
                sportProvider,
                user,
                event
            );

        if (externalMatchSource == EventExternalMatchSource.revSport) {
            this.revSportIntegration = new RevSportIntegrationSupport(this, sportProvider)
        }
    }

    update(attributes: UpdatableEventAttributes) {
        return this.user.fetchVerificationToken().then((userToken) => {
            var bodyAttributes = {
                name: attributes.name,
                competitionStartDate: moment(attributes.competitionDateRange.startDate).format(
                    'YYYY-MM-DD HH:mm:ss Z'
                ),
                competitionEndDate: moment(attributes.competitionDateRange.endDate).format(
                    'YYYY-MM-DD HH:mm:ss Z'
                ),
            }
            if (attributes.heroImageURL) {
                bodyAttributes['heroImageURL'] = attributes.heroImageURL;
            }
            if (attributes.color) {
                bodyAttributes['color'] = attributes.color
            }

            const body = {
                eventID: this.event.id,
                userToken: userToken,
                attributes: bodyAttributes,
            };

            var apiPath = '/api/events/update';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status == 200) {
                    this.event.attributes.name = attributes.name;
                    return Promise.resolve(this.event);
                } else {
                    return Promise.reject(`Invalid status: ${response.status}`);
                }
            });
        });
    }

    addArticle(thumbnailUrl: string, title: string, url: string) {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                eventID: this.event.id,
                userToken: userToken,
                attributes: {
                    thumbnailUrl,
                    title,
                    url
                },
            };
            var apiPath = '/api/events/addArticle';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status == 201) {
                    const articleData = response.data;
                    console.log('articleData', articleData)
                    const articleID = articleData.id
                    if (typeof articleID !== 'string') {
                        return Promise.reject('Invalid article ID')
                    }
                    const articleDateString = articleData.publishDate
                    const publishDate = dateFromFirebaseString(articleDateString)
                    if (!publishDate) {
                        return Promise.reject('Invalid publish date')
                    }
                    const article: EventArticle = {
                        id: articleID,
                        publishDate,
                        thumbnailUrl,
                        title,
                        url
                    }
                    return Promise.resolve(article);
                } else {
                    return Promise.reject(`Invalid status: ${response.status}`);
                }
            });
        });
    }

    removeArticle(article: EventArticle) {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                eventID: this.event.id,
                userToken: userToken,
                articleID: article.id,
            };
            var apiPath = '/api/events/removeArticle';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status == 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`Invalid status: ${response.status}`);
                }
            });
        });
    }

    postNotification(message: string) {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                eventID: this.event.id,
                userToken: userToken,
                message,
            };
            var apiPath = '/api/events/postNotification';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status == 201) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`Invalid status: ${response.status}`);
                }
            });
        });
    }

    addInformationItem(text: string, content: EventInformationContent): Promise<EventInformationItem> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                eventID: this.event.id,
                userToken: userToken,
                text,
                content,
            };
            var apiPath = '/api/events/informationItems/addInformationItem';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 201) {
                    const informationItem = response.data;
                    return Promise.resolve(informationItem);
                } else {
                    return Promise.reject(`addInformationItem failed with status: ${response.status}`);
                }
            });
        });
    }

    updateInformationItem(item: EventInformationItem, text: string, content: EventInformationContent): Promise<EventInformationItem> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                eventID: this.event.id,
                userToken: userToken,
                informationItemID: item.id,
                text,
                content,
            };
            var apiPath = '/api/events/informationItems/updateInformationItem';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200) {
                    const updatedItem = response.data;
                    return Promise.resolve(updatedItem);
                } else {
                    return Promise.reject(`updateInformationItem failed with status: ${response.status}`);
                }
            });
        });
    }

    removeInformationItem(item: EventInformationItem): Promise<void> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                eventID: this.event.id,
                userToken: userToken,
                informationItemID: item.id,
            };
            var apiPath = '/api/events/informationItems/removeInformationItem';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(`removeInformationItem failed with status: ${response.status}`);
                }
            });
        });
    }

    openRegistration(maxNumberOfEntries: number) {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken: userToken,
                eventID: this.event.id,
                numberOfEntries: maxNumberOfEntries,
            };
            var apiPath = '/api/events/openRegistration';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios
                .post(apiPath, body)
                .then((response) => {
                    if (response.status === 200) {
                        const registration = new EventRegistrationImplementation(this.event, {
                            maxNumberOfEntries: maxNumberOfEntries,
                        });
                        this.event.registration = registration;
                        return Promise.resolve(registration);
                    } else if (response.status === 401) {
                        return Promise.reject(EventRegistrationError.unauthenticated);
                    } else if (response.status === 403) {
                        return Promise.reject(EventRegistrationError.missingPermission);
                    } else if (response.status === 500) {
                        return Promise.reject(EventRegistrationError.serverFailure);
                    } else {
                        return Promise.reject();
                    }
                })
                .catch((error) => {
                    return Promise.reject(`openingRegistration failed with error: ${error}`);
                });
        });
    }

    closeRegistration() {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken: userToken,
                eventID: this.event.id,
            };
            var apiPath = '/api/events/closeRegistration';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200) {
                    this.event.registration = undefined;
                    return Promise.resolve();
                } else if (response.status === 401) {
                    return Promise.reject(EventRegistrationError.unauthenticated);
                } else if (response.status === 403) {
                    return Promise.reject(EventRegistrationError.missingPermission);
                } else if (response.status === 500) {
                    return Promise.reject(EventRegistrationError.serverFailure);
                } else {
                    return Promise.reject();
                }
            });
        });
    }

    delete() {
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                userToken: token,
                eventID: this.event.id,
            };
            // if on dev, use localhost
            // if on staging/prod, use deployment url
            var apiPath = '/api/events/delete';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(response);
                }
            });
        });
    }

    addVenue(name: VenueName, timezone: string, externalID?: string): Promise<EventVenue> {
        return this.user.fetchVerificationToken().then((userToken) => {
            var venueData = {
                name: name.name,
                timezone
            };
            if (name.subvenueName) {
                venueData['subvenueName'] = name.subvenueName;
            }
            if (externalID) {
                venueData['externalID'] = externalID;
            }
            const body = {
                userToken: userToken,
                eventID: this.event.id,
                venueData,
            };
            var apiPath = '/api/events/venues/addVenue';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 201) {
                    const venueID = response.data.id;
                    return this.sportProvider.venueResolver(this.event.id, venueID).asAPromise();
                } else {
                    return Promise.reject();
                }
            });
        });
    }

    updateVenue(venue: EventVenue, name: VenueName, timezone: string): Promise<EventVenue> {
        return this.user.fetchVerificationToken().then((userToken) => {
            var venueData = {
                name: name.name,
                timezone
            };
            if (name.subvenueName) {
                venueData['subvenueName'] = name.subvenueName;
            }
            const body = {
                userToken: userToken,
                eventID: this.event.id,
                venueID: venue.id,
                venueData,
            };

            var apiPath = '/api/events/venues/updateVenue';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                console.log('updateResponse', response);
                console.log('updateResponse.data', response.data);
                if (response.status === 200) {
                    var updatedVenue = venue;
                    updatedVenue.name = name.name;
                    updatedVenue.subvenueName = name.subvenueName;
                    updatedVenue.timezone = timezone;
                    return Promise.resolve(updatedVenue);
                } else {
                    return Promise.reject();
                }
            }).catch((error) => {
                console.log('updateVenue error', error);
                return Promise.reject(error);
            });
        });
    }

    removeVenue(venue: EventVenue): Promise<void> {
        return this.user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken: userToken,
                eventID: this.event.id,
                venueID: venue.id,
            };
            var apiPath = '/api/events/venues/removeVenue';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject();
                }
            });
        });
    }
}

class EventRegistrationImplementation implements EventRegistration {
    event: FirebaseBackedEvent;
    attributes: EventRegistrationAttributes;

    constructor(event: FirebaseBackedEvent, attributes: EventRegistrationAttributes) {
        this.event = event;
        this.attributes = attributes;
    }

    requestEntryToCompetition(
        competition: Competition,
        requestingUser: User,
        backingTeam: Team,
        metadata: CompetitionEntryMetadata,
        roster: RegistrationRosterEntry[]
    ) {
        return requestingUser.fetchVerificationToken().then((token) => {
            const rosterData = roster.reduce((currentRosterData, player) => {
                currentRosterData[player.id] = {
                    name: player.name,
                    capNumber: player.capNumber.databaseValue,
                    position: player.position.databaseValue,
                    backingPlayer: player.id,
                };
                return currentRosterData;
            }, {});
            const body = {
                userToken: token,
                eventID: this.event.id,
                competitionID: competition.id,
                entryRequest: {
                    teamID: backingTeam.id,
                    name: metadata.name,
                    abbreviation: metadata.abbreviation,
                    color: {
                        red: metadata.color.red,
                        blue: metadata.color.blue,
                        green: metadata.color.green,
                    },
                    roster: rosterData,
                },
            };
            // if on dev, use localhost
            // if on staging/prod, use deployment url
            var apiPath = '/api/events/registerTeam';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 201) {
                    const entryRequestID = response.data.id || '';

                    return this.event.sportProvider
                        .competitionEntryRequestResolver(competition.id, entryRequestID)
                        .asAPromise();
                } else {
                    return Promise.reject('Unexpected response when requesting entry');
                }
            });
        });
    }

    registerForCompetitions(
        user: User,
        requests: CompetitionRegistrationRequest[]
    ): Promise<CompetitionPayment> {
        return user.fetchVerificationToken().then((token) => {
            const entryRequests = requests.map((request) => {
                const roster = request.roster;
                const rosterData = roster.reduce((currentRosterData, player) => {
                    currentRosterData[player.id] = {
                        name: player.name,
                        capNumber: player.capNumber.databaseValue,
                        position: player.position.databaseValue,
                        backingPlayer: player.id,
                    };
                    return currentRosterData;
                }, {});
                const backingTeam = request.backingTeam;
                const metadata = request.metadata;
                const entryData = {
                    competitionID: request.competition.id,
                    teamID: backingTeam.id,
                    name: metadata.name,
                    abbreviation: metadata.abbreviation,
                    color: {
                        red: metadata.color.red,
                        blue: metadata.color.blue,
                        green: metadata.color.green,
                    },
                    roster: rosterData,
                };
                return entryData;
            });
            const body = {
                userToken: token,
                eventID: this.event.id,
                entryRequests,
            };
            // if on dev, use localhost
            // if on staging/prod, use deployment url
            var apiPath = '/api/events/payments/createEntryPaymentSession';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios
                .post(apiPath, body)
                .then((response) => {
                    console.log(response);
                    const status = response.status;
                    if (status === 201) {
                        const entryRequestIDs = response.data.competitionEntryIDs || {};

                        return Promise.all(
                            Object.keys(entryRequestIDs).map((entryRequestID) => {
                                const competitionID = entryRequestIDs[entryRequestID];
                                return this.event.sportProvider
                                    .competitionEntryRequestResolver(competitionID, entryRequestID)
                                    .asAPromise();
                            })
                        ).then((entryRequests) => {
                            return Promise.resolve({
                                checkoutURL: response.data.checkoutURL,
                                entryRequests,
                            });
                        });
                    } else {
                        return Promise.reject('Unexpected response when requesting entry');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    return Promise.reject(error);
                });
        });
    }

    validatePayment(
        user: User,
        paymentID: string
    ): Promise<Map<string, { competition: Competition; competitionEntries: CompetitionEntry[] }>> {
        return user.fetchVerificationToken().then((userToken) => {
            const body = {
                userToken,
                sessionID: paymentID,
            };
            var apiPath = '/api/events/payments/validateEntryPayment';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    const competitionEntries = response.data.competitionEntries;
                    const competitionEntryPromises = Object.keys(competitionEntries).map(
                        (competitionID) => {
                            const competitionEntryIDs = Object.keys(
                                competitionEntries[competitionID]
                            );
                            const competitionPromises = competitionEntryIDs.map(
                                (competitionEntryID) => {
                                    return this.event.sportProvider
                                        .competitionEntryResolver(competitionID, competitionEntryID)
                                        .asAPromise();
                                }
                            );
                            return Promise.all(competitionPromises).then((competitionEntries) => {
                                if (competitionEntries.length === 0) {
                                    return this.event.sportProvider
                                        .competitionResolver(competitionID)
                                        .asAPromise()
                                        .then((competition) => {
                                            return {
                                                competition,
                                                competitionEntries: [],
                                            };
                                        });
                                } else {
                                    const competition = competitionEntries[0].competition;
                                    return {
                                        competition,
                                        competitionEntries,
                                    };
                                }
                            });
                        }
                    );
                    return Promise.all(competitionEntryPromises).then(
                        (resolvedCompetitionEntries) => {
                            var competitionEntryMap = new Map();
                            resolvedCompetitionEntries.forEach((resolvedCompetitionEntry) => {
                                competitionEntryMap.set(
                                    resolvedCompetitionEntry.competition.id,
                                    resolvedCompetitionEntry
                                );
                            });
                            return Promise.resolve(competitionEntryMap);
                        }
                    );
                } else {
                    return Promise.reject(`Payment validation failed with: ${response}`);
                }
            });
        });
    }
}
