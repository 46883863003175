import { Dropdown, DropdownButton, ListGroup, Spinner } from "react-bootstrap";
import { Competition } from "../../../models/interfaces/Competition";
import { CompetitionEntry } from "../../../models/interfaces/Competitions/CompetitionEntry";
import styled from "styled-components";

interface CompetitionSelectProps {
    selectedCompetition: Competition;
    competitions: Competition[];
    onCompetitionSelect: (competition: Competition) => void;
}

function CompetitionPickerDropDownView({ selectedCompetition, competitions, onCompetitionSelect }: CompetitionSelectProps) {
    const handleCompetitionSelect = (competition: Competition) => {
        onCompetitionSelect(competition);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="secondary">
                {selectedCompetition ? selectedCompetition.attributes.name : "Select a competition"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {competitions.map((competition) => {
                    const isActive = competition.id === selectedCompetition.id;
                    return (<Dropdown.Item key={competition.id} active={isActive} onClick={() => handleCompetitionSelect(competition)}>
                        {competition.attributes.name}
                    </Dropdown.Item>)
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export function CompetitionPickerListView({ selectedCompetition, competitions, onCompetitionSelect }: CompetitionSelectProps) {
    return (
        <ListGroup>
            {competitions.map((competition) => (
                <ListGroup.Item
                    key={competition.id}
                    active={selectedCompetition.id === competition.id}
                    onClick={() => {
                        if (selectedCompetition.id !== competition.id) {
                            onCompetitionSelect(competition);
                        }
                    }}
                >
                    {competition.attributes.name}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export interface CompetitionFilterProps {
    competitionSelect: CompetitionSelectProps,
    teamSelect: TeamSelectionProps
}

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 10px;
    @media only screen and (max-width: 600px) {
        justify-content: center;
        gap: 5px;
    }
`

export function CompetitionFilterView({ competitionSelect, teamSelect }: CompetitionFilterProps) {
    return (
    <DropdownContainer>
        <CompetitionPickerDropDownView
            competitions={competitionSelect.competitions}
            selectedCompetition={competitionSelect.selectedCompetition}
            onCompetitionSelect={competitionSelect.onCompetitionSelect}
        />
        <CompetitionTeamPicker
            competitionEntries={teamSelect.competitionEntries}
            selectedCompetitionEntry={teamSelect.selectedCompetitionEntry}
            onSelectCompetitionEntry={teamSelect.onSelectCompetitionEntry}
        />
    </DropdownContainer>)
}

interface TeamSelectionProps {
    competitionEntries: CompetitionEntry[] | undefined;
    selectedCompetitionEntry?: CompetitionEntry;
    onSelectCompetitionEntry: (CompetitionEntry?) => void;
}

function CompetitionTeamPicker({ competitionEntries, selectedCompetitionEntry, onSelectCompetitionEntry }: TeamSelectionProps) {
    const isLoading = competitionEntries === undefined
    return (
        <Dropdown>
            <Dropdown.Toggle disabled={isLoading} variant={selectedCompetitionEntry ? "primary" : "secondary"} id="dropdown-competition-entry" style={{ minWidth: '150px' }}>
                { isLoading ? <Spinner size="sm" animation="border"/> : (selectedCompetitionEntry ? selectedCompetitionEntry.name : "All teams")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {competitionEntries?.map((entry) => {
                    const isActive = selectedCompetitionEntry?.id === entry.id;

                    return <Dropdown.Item
                        key={entry.id}
                        active={isActive}
                        onClick={() => {
                            if (selectedCompetitionEntry?.id !== entry.id) {
                                onSelectCompetitionEntry(entry);
                            } else {
                                onSelectCompetitionEntry(undefined);
                            }
                        }}
                    >
                        {entry.name}
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}