import { Nav, Navbar, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function TitleBar() {
    if (window.location !== window.parent.location) {
        return <></>;
    }
    return (
        <Navbar className='myTitleBar' expand="sm" style={{borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: 'rgb(0,0,0, 0.1)'}}>
            <Link to='/'>
                <Image
                    className="mx-2"
                    src={`${process.env.PUBLIC_URL}/kap7_logo.png`}
                    height={50}
                />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent: 'end'}}>
                <Nav className='myNav'>
                    <a style={{ padding: '10px' }} href="/myorganizations">My Teams</a>
                    <a style={{ padding: '10px' }} href="/myevents">My Tournaments</a>
                    <a style={{ padding: '10px' }} href="/myaccount">Account</a>
                </Nav>
            </Navbar.Collapse>
        </Navbar>        
    );
}
