import { ListProvider } from "./ListProvider";
import { Match } from "./Match";
import { Resolver } from "./Resolver";

export interface Venue {
    id: string
    name: string
    subvenueName?: string

    timezone: string
}

export interface VenueNames {
    name: string
    subvenueName?: string
}

export interface EventVenue extends Venue {
    matchProvider: ListProvider<Resolver<Match>>;
}

export function isEventVenue(venue: Venue): venue is EventVenue {
    return (venue as EventVenue).matchProvider !== undefined
        && (venue as EventVenue).name !== null;
}

export function sortVenues(left: Venue, right: Venue) {
    if (left.name < right.name) {
        return -1;
    } else if (left.name > right.name) {
        return 1;
    } else if (left.subvenueName && right.subvenueName) {
        if (left.subvenueName < right.subvenueName) {
            return -1
        } else if (left.subvenueName > right.subvenueName) {
            return 1
        } else {
            return 0
        }
    } else {
        return 0
    }
}