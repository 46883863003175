import { MatchEntry } from './MatchEntry';
import {
    SortedStatProvider,
    Stat,
    MatchSegmentStats,
    StatTypeDescribing,
    TableWorker,
} from './Stat';
import { Competition } from './Competition';
import { User } from './User';
import { CapNumber, RosterEntry } from './RosterEntry';
import {
    MatchViewTextComponents,
    PlayerStatDescription,
} from './ViewDescriptions/MatchViewDescription';
import { DisplayTextCategory } from './SportProvider';
import { Venue } from './Venue';

export function isMatch(candidate): candidate is Match {
    return (
        (candidate as Match).lightCapTeam !== undefined &&
        (candidate as Match).darkCapTeam !== undefined
    );
}

export enum MatchStatus {
    scheduled,
    inProgress,
    completed,
}

export enum MatchSegmentOption {
    quarters,
    halves,
}

export interface MatchSegment {
    databaseValue: number;
    displayName: string;
}

export interface MatchSegmentDescription {
    matchSegments: MatchSegment[];
    durationInSeconds: number;
}

export interface MatchParameters {
    regularTimeMatchSegmentOption: MatchSegmentOption;
    regularTimeDescription: MatchSegmentDescription;
    overtimeDescription: MatchSegmentDescription;

    initialNumberOfTimeouts: number;
}

export interface MatchPlayerSummary {
    player: RosterEntry;
    weight: number; // represents the total 'player score' in the match
    stats: Stat[];
}

export interface MatchViewConfiguration {
    shouldDisplayStat: (stat: Stat) => boolean;

    teamSummaries: (matchStats: MatchSegmentStats[]) => {
        title: string;

        lightCapStats: Stat[];
        darkCapStats: Stat[];

        displayTextForStats(teamStats: Stat[], opponentStats: Stat[]): MatchViewTextComponents;
    }[];

    playerSummaries: (matchStats: MatchSegmentStats[]) => {
        title: string;

        lightCapPlayerArray: MatchPlayerSummary[];
        darkCapPlayerArray: MatchPlayerSummary[];

        displayTextForStats(stats: Stat[]): MatchViewTextComponents;
    }[];
}

export interface MatchMetadata {
    date: Date 
    venue?: Venue
}

export interface Match {
    id: string;

    status: MatchStatus;
    date: Date;
    externalID?: string;

    venue?: Venue

    lightCapTeam: MatchEntry;
    darkCapTeam: MatchEntry;

    competition: Competition;

    parameters: MatchParameters;
    statProvider: SortedStatProvider;

    scoreForTeam(team: MatchEntry): Promise<number | undefined>;

    capChangeForPlayer(player: RosterEntry): Promise<CapNumber | undefined>;

    fanViewConfiguration: MatchViewConfiguration;
    scoreSheetConfiguration: MatchViewConfiguration;
    officialViewConfiguration: MatchViewConfiguration;

    // CBTODO: should this actually live here?
    playerSummary: (
        summaryDescription: PlayerStatDescription,
        matchStats: MatchSegmentStats[]
    ) => {
        title: string;
        lightCapPlayerArray: MatchPlayerSummary[];
        darkCapPlayerArray: MatchPlayerSummary[];

        displayTextForStats(stats: Stat[]): string;
    };

    adminForUser: (user: User) => Promise<MatchAdmin>;
}

export interface MatchAdmin {
    match: Match;
    user: User;

    updateMetadata(metadata: MatchMetadata): Promise<Match>;

    start(): Promise<void>;

    recordingOptionGroups: MatchEventRecordingGroup[];
    recordingOptionForCSVKey(csvKey: string): MatchEventRecordingOption | undefined;
    addStat(
        matchSegment: MatchSegment,
        timestamp: number,
        performer: MatchEntry | RosterEntry | TableWorker,
        typeDescription: StatTypeDescribing,
        remarks?: string
    ): Promise<Stat>;

    updateStat(
        stat: Stat,
        matchSegment: MatchSegment,
        timestamp: number,
        performer: MatchEntry | RosterEntry | TableWorker,
        typeDescription: StatTypeDescribing,
        remarks?: string
    ): Promise<Stat>;

    removeStat(stat: Stat): Promise<void>;
    removeStats(stats: Stat[]): Promise<void>;

    displayTextForAddedStat(
        stat: Stat,
        existingStats: Stat[]
    ): Promise<{ text: string; category: DisplayTextCategory }>;

    setCapChangeForPlayer(capNumber: CapNumber | undefined, player: RosterEntry): Promise<void>;

    finishMatch(verification?: ResultVerification): Promise<void>;
    getResultVerificationPromise(): Promise<ResultVerification>;

    updateResultVerification(verification?: ResultVerification): Promise<void>;

    setMatchScore(lightTeamScore: number, darkTeamScore: number): Promise<void>;
}

export interface MatchEventRecordingGroup {
    displayName: string;
    recordingOptions: MatchEventRecordingOption[];
}

export interface MatchEventRecordingOption {
    displayName: string;
    shortcutKey: string;

    typeDescription: StatTypeDescribing;

    requiresPlayer: boolean;
    requiresRemark: boolean;
}

export enum RemoveMatchResult {
    missingPermission,
    parentObjectNotFound,
    matchNotFound,
    serverFailed,
}

export interface WinningTeam {
    light: boolean;
    dark: boolean;
}

export interface ResultVerification {
    officialName: string;
    email: string;
    // verificationObject will be a base64 string which is provided by canvas.toDataURL()  https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
    verificationObject: string;

    // If this is populated, it means that we've changed the match stats after verification has been provided
    invalidatedDate?: Date;
}
